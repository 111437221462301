<template>
  <div>
    <div class="row mx-0 pt-3">
      <div class="col-md-12 pl-0 row mx-0">
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Companies</span>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="job-function"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                {{ props.item.company_name }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                {{ props.item.office_phone_1 }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.feature == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeFeature(props.item)"
                />
                {{ props.item.feature ? "Featured" : "Normal" }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.spotlight == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeSpotlight(props.item)"
                />
                {{ props.item.spotlight ? "Disabled" : "Enabled" }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.verify == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeVerify(props.item)"
                />
                {{ props.item.verify ? "Verified" : "Unverified" }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 12px; font-weight: 500 !important"
              >
                {{ props.item.website }}
              </td>
              <td class="Main_Blue">
                <!-- <router-link to="/employer/{{props.item.company-name}}"> -->
                <router-link
                  style="font-size: 15px !important"
                  :to="
                    '/employer/' +
                    props.item.company_name
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '')
                  "
                  class="company-name"
                >
                  <v-btn class="py-2 ch_btn_silver_rounded_0" fab small>
                    <img
                      src="\images/job_application/View_Icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                </router-link>
                <!-- </router-link> -->
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="jobFunction"
            :rules="nameRules"
            label="Job function name"
            required
          ></v-text-field>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="createJobFunction"
          >
            Save
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="jobFunction"
            :rules="nameRules"
            label="Job function name"
            required
          ></v-text-field>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="updateJobFunction"
          >
            Update
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      jobFunction: "",
      job_function_id: null,
      nameRules: [
        (v) => !!v || "Job function is required",
        (v) =>
          (v && v.length <= 20) ||
          "Job function must be less than 20 characters",
      ],
      headers: [
        { text: "Company Name", value: "company_name" },
        { text: "Office Phone#", value: "office_phone_1" },
        { text: "Feature", value: "feature" },
        { text: "Spotlight", value: "spotlight" },
        { text: "Verification", value: "verify" },
        { text: "Website", value: "website" },
        { text: "Action", value: "Action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    cancel() {
      this.createForm = false;
      this.updateForm = false;
      this.jobFunction = "";
    },
    createFormEnable() {
      this.jobFunction = "";
      this.createForm = true;
      this.updateForm = false;
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/get-companies`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    changeFeature(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/update-feature-company/${item.id}`, {
          feature: item.feature,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeSpotlight(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/update-spotlight-company/${item.id}`, {
          spotlight: item.spotlight,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeVerify(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/update-verify-company/${item.id}`, {
          verify: item.verify,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.jobFunction = item.job_function_name;
      this.job_function_id = item.id;
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/delete-user/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "User deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
